@import '/src/Variable.scss';

.slMainPostCard {
  border-radius: $border-radius-md !important;
  border: 1px solid $card-border !important;
  background: #fff;
  margin-bottom: 20px !important;
  height: 535px;
  cursor: pointer;

  .slMainPostMetaRow {
    // margin-top: 10px;
    // margin-bottom: 10px;

    .slMainPostCardMeta {
      display: flex;
      margin-bottom: 16px;
      padding: 0 16px;

      .slMainPostCardMetaDetail {
        margin-left: 8px;

        .slMainPostCardMetaName {
          color: $black;
          font-weight: 600;
          font-size: 20px;
        }

        .slMainPostCardMetaCourse {
          color: $black;
        }

        .slMainPostCardMetaProgramme {
          color: $primary-tab;
        }
      }
    }
  }

  .slMainPostCardMetaFooter {
    padding: 0 16px;
  }

  .slMpUserDetails {
    display: flex;
    margin: 10px 10px 30px 10px;
    .slMpUserTitle {
      margin-left: 10px;
    }
  }

  .slMainPostDescription {
    font-weight: 400;
    margin-top: 10px;
  }

  .slMpLikeButton {
    border: unset;
    color: #b9b9b9;
  }

  .slMpShareButton {
    border: unset;
    color: #b9b9b9;
  }

  .slMpLikeText {
    color: #b9b9b9;
  }

  .slMpShareText {
    color: #b9b9b9;
  }

  .slMpDescription {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 400;
    height: 42px;
  }

  .slMpDivider {
    padding-left: 10px;
    padding-right: 10px;
  }

  .slMpGalleryRow {
    max-width: 100%;
    height: auto;

    .ant-card-grid {
      box-shadow: none;
    }
  }

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px;

    .ant-divider-horizontal {
      margin: 12px 0;
    }
  }
}

.slMainPostCard:hover {
  box-shadow: 0 0 30px $gray-light;
}

@media only screen and (max-width: 720px) {
  .slMainPostCard {
    .slMainPostMetaRow {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
