@import '/src/Variable.scss';

.main-content {
  display: flex;
  flex-direction: column;
  height: auto !important;
  overflow: auto !important;
}

.whiteBackground {
  background-color: $white !important;
  padding: 0 10px !important;
}

.grayBackground {
  background-color: $gray-bg !important;
}

.wordpressTabTile {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
