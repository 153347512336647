.aiButton1 {
  margin: 3px !important;
  font-size: small !important;
  padding: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: solid 2px !important;
  border-radius: 8px !important;
  // color: rgb(0,89,147) !important;
}

.aiButtonLayoutMain {
  margin-left: 15px;
}

// .textAreaDividerIn {
//   display: flex !important ;
//   align-items: center !important;
//   justify-content: center !important;
//   width: 10px !important;
//   background-color: #d9d9d9 !important;
//   left: 21px !important;
//   min-width: 93% !important;
// }

.secureIcon {
  color: rgb(37, 170, 112);
  padding-bottom: 2px;
}

.starIcon {
  color: rgb(255, 71, 71);
  margin-bottom: 7px;
}

.inUserChatText {
  padding-left: 5px;
}

.typeMessageContainer {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 4px;
  height: 100%;
  position: relative;
  width: 100%;

  .textArea-iFrame {
    border-radius: 0;
    height: 150px;
  }

  .suggestionButton-iFrame {
    margin: 0.5px;
    border: 2px solid;
    border-radius: 8px;
    .textContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .textSpan {
        margin-right: 5px;
      }

      .suggestionText {
        padding-bottom: 2px;
      }
    }
  }

  .ant-input {
    border: none !important;
    resize: none;
    outline: none !important;
    box-shadow: none !important;
  }

  .textArea {
    border-radius: 0;
    height: 200px;
  }

  .ant-btn-background-ghost.ant-btn-primary {
    border-color: unset;
  }

  .suggestionButton {
    border: 2px solid;
    border-radius: 8px;
    margin: 2px;

    .textContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .textSpan {
        margin-right: 5px;
      }

      .suggestionText {
        padding-bottom: 2px;
      }
    }
  }

  .questionsHeading {
    font: 'Roboto';
    color: #828282;
    padding: 2px;
  }
}

.optionsQuestion {
  .optionsQuestionTitle {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }

  .optionsQuestionCheckboxGroup {
    margin-top: 10px;
    .radioGroupText {
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .optionsQuestionAsnwerTitle {
    margin-top: 10px;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .optionsQuestionAsnwerTextbox {
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid #b9b9b9;
    // background: #fff;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .aiButton1 {
    // display: none !important;
  }

  .suggestionButtonsContainer {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    padding-bottom: 12px;
  }

  .suggestionButton {
    max-width: 100%;
    .textContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .suggestionText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 1360px) {
  .typeMessageContainer {
    .textArea-iFrame {
      border-radius: 0;
      height: 100px !important;
    }

    .suggestionButton-iFrame {
      margin: 0.5px;
      border: 2px solid;
      border-radius: 8px;
      .textContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .textSpan {
          margin-right: 5px;
        }

        .suggestionText {
          padding-bottom: 2px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .typeMessageContainer {
    .textArea-iFrame {
      border-radius: 0;
      height: 100px !important;
    }

    .suggestionButton-iFrame {
      margin: 0.5px;
      border: 2px solid;
      border-radius: 8px;
      .textContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .textSpan {
          margin-right: 5px;
        }

        .suggestionText {
          padding-bottom: 2px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1368px) {
  .typeMessageContainer {
    .textArea-iFrame {
      border-radius: 0;
      height: 150px;
    }

    .suggestionButton-iFrame {
      margin: 0.5px;
      border: 2px solid;
      border-radius: 8px;
      .textContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .textSpan {
          margin-right: 5px;
        }

        .suggestionText {
          padding-bottom: 2px;
        }
      }
    }
  }
}
