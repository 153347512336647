.slick-slider {
  width: 100%;
}

.slick-slide {
  transition: transform 0.3s ease;
}

// .slick-dots {
//   bottom: -30px;
//   li button:before {
//     color: #fff;
//   }
//   li.slick-active button:before {
//     color: #000;
//   }
// }

.slick-slide img {
  opacity: 0.6;
  display: block;
  width: 90%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.slick-prev,
.slick-next {
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

// .slick-prev {
//   left: 10px !important;
// }

// .slick-next {
//   right: 10px !important;
// }

.slick-prev:before,
.slick-next:before {
  content: '';
}

.slick-prev,
.slick-next {
  font-size: 20px;
  line-height: 1;
  background: rgba(71, 47, 47, 0.5);
  color: rgb(87, 28, 28);
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
  text-align: center;
  opacity: 0.75;
  &::before {
    color: black !important;
  }
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 1;
}

.slick-slide.slick-current img {
  opacity: 1;
}

.slick-slider.min-items .slick-slide {
  width: 200px !important;
}

.slick-slider.single-item-mobile .slick-slide {
  width: 160px !important;
}

@media screen and (max-width: 1366px) {
  .slick-slider.min-items .slick-slide {
    width: 170px !important;
  }
}
