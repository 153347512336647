@import '/src/Variable.scss';

.personCard {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 16px;
  &.enableWordpressCard {
    .ant-card {
      width: 80%;
    }
  }
  .ant-card {
    border-radius: $border-radius-md;
    border: 1px solid $card-border;
    width: 100%;
    .ant-card-body {
      padding: 0px 0 30px 0;
      .title {
        padding: 0 6px;
        max-width: 100%;
        font-size: 17px;
        line-height: 27px;
        font-weight: 500;
        color: #222831;
      }
      .studiesTitle {
        max-width: 100%;
        padding: 0 6px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #393e46;
        .courseLevelTitle {
          color: #5c8374;
          font-size: 13px;
          line-height: 15px;
        }
      }
      .roleTitle {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: rgb(74, 71, 71);
        .workTitle {
          color: #00adb5;
          font-size: 13px;
          line-height: 15px;
        }
      }
      .languages {
        margin: 10px 0 10px 0;
        .languagesTitle {
          line-height: 20px;
          font-weight: 400;
          color: #545454;
          margin: 0;
          font-size: 14px;
        }
        .languagesIn {
          font-size: 13px;
          line-height: 18px;
          color: $gray-text;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            position: relative;
            margin: 0 12px 0 0;
            &:after {
              content: '.';
              position: absolute;
              bottom: 4px;
              margin-left: 4px;
            }
            &:nth-last-child(1) {
              margin: 0;
              &:after {
                display: none;
              }
            }
          }
        }
        .countryContainer {
          font-size: 13px;
          line-height: 18px;
          color: $gray-text;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: flex;
            align-items: center;
            position: relative;
            &:after {
              position: absolute;
              bottom: 4px;
              margin-left: 4px;
            }
            &:nth-last-child(1) {
              margin: 0;
              &:after {
                display: none;
              }
            }
          }
          .userCoutryFlag {
            padding-left: 6px;
          }
          .countryName {
            margin-left: 4px;
            color: #808080;
            padding-right: 6px;
          }
        }
        }
      }
      .hobby {
        .hobbyTitle {
          line-height: 15px;
          font-weight: 400;
          color: #545454;
          margin: 0;
        }
        .hobbyIconArea {
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 2px 0 0 0;
          .hobbyIconLayout {
            position: relative;
            &:after {
              content: '.';
              position: absolute;
              bottom: 4px;
              margin-left: -7px;
              color: $gray-text;
            }
            &:nth-last-child(1) {
              .hobbyIcon {
                padding: 0;
              }
              &:after {
                display: none;
              }
            }
            .hobbyIcon {
              height: 20px;
              padding: 0 12px 0 0;
              opacity: 0.5;
            }
          }
        }
      }
      .buddyAvgResponseTime {
        padding-top: 16px;
        padding-bottom: 12px;
        color: #5d5d5d;
      }
      .userChatButton {
        margin-top: 40px !important;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        // margin-top: 10px;
        .inUserChatButton {
          display: flex;
          width: auto;
          height: auto;
          align-items: center;
          justify-content: center;
          border-radius: $border-radius-sm;
          background-color: $primary;
          padding: 8px 16px;
          svg {
            margin: 0 8px 0 0;
            font-size: $font-md;
          }
          span {
            font-size: $font-md;
          }
        }
        .viewProfileButton {
          color: $primary;
          margin: 5px 0 0 0;
        }
      }
    }
  }
  .ant-card:hover {
    box-shadow: 0 0 30px $gray-light;
  }

@media only screen and (max-width: 768px) {
  .personCard {
    .coverImageForeground {
      width: 70px;
      .coverImageForegroundUserImage {
        width: 70px;
        height: 70px;
        // border: unset;
        &.imgOutline {
          border: 3px solid var(--theme-color);
          outline: none;
        }
      }
    }
    .ant-card {
      height: 320px !important;
      .ant-card-body {
        .personCardIn {
          height: 110px !important;
          .title {
            font-size: 16px;
            font-weight: 500;
          }
        }
        .userChatButton {
          margin-top: 0px !important;
          .inUserChatButton {
            svg {
              margin: 0 4px 0 0 !important;
              font-size: $font-sm !important;
            }
          }
          .viewProfileButton {
            margin: 5px 0 0 0 !important;
          }
        }
      }
    }
  }
}

html {
  background-color: #fafafa;
}

// .personCard {
//   display: flex;
//   flex-wrap: wrap;
//   border-radius: 50% !important;
// }

// .inPersonCard {

//   width: 250px;
//   height: 450px;
//   border-top-left-radius: 10px !important;
//   border-top-right-radius: 10px !important;
//   border-bottom-left-radius: 10px !important;
//   border-bottom-right-radius: 10px !important;
//   border: solid rgb(228, 224, 224) 2px !important;
//   background-color: #fafafa;
// }

// .hobbyText{
//   font-size: 11px;
//   font-weight: bold;
//   margin-bottom: 0%;
//   color: #5d5d5d;
// }
.img {
  border-radius: 60% !important;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: solid 3px white;
}

.userCountryImg {
  // width: auto;
  // height: 38px;
}

.coverImageForeground {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
  width: 35%;
  text-align: center;
  position: relative;
  .coverImageForegroundUserImage {
    &.imgOutline {
      outline: 3px solid var(--theme-color);
    }
  }
}

.cover {
  position: relative;
  z-index: 1;
}

.coverImageBackround {
  position: absolute;
  width: 99.8%;
  height: 100px;
  object-fit: cover;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  top: 1px;
}

.cardview {
  margin: 100;
}

.copyLinkButton {
  width: 30px;
  height: 30px;
  padding-top: 20px;
  padding-right: 40px;
}

.incopyLinkButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #0000008a !important;
}

.userRoleBadge {
  padding-left: 70px;
  padding-top: 10px;
}

.userBadge {
  position: absolute;
  top: 60px;
  left: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 2px solid $white;
  overflow: hidden;
}

.userCoutryFlag {
  span {
    width: 24px;
    margin-left: 3px;
    height: 16px;
  }
}

// .userChatButton {
//   display: flex;
//   font-size: 10px;
//   // height: 10px;
//   width: 100%;
//   flex-direction: column;
//   align-items: center;
// }

// .inUserChatButton {
//   display: flex !important;
//   width: 42%;
//   align-items: center !important;
//   justify-content: center !important;
//   border-radius: 7px !important;
//   background-color: rgb(0,89,147) !important;
//   border-color: rgb(0,89,147) !important;
//   padding-left: 3px !important;
//   padding-right: 3px !important;
//   // margin-top: 15px;
// }

.inUserChatTextPrsnCrd {
  padding-left: 7px;
  font-size: 11px;
}

.topPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

// .viewProfileButton {
//   display: flex;
//   align-items: center;
//   margin-top: 7px;
// }
// .viewProfileButtonIn {
//   font-size: 11px !important;
//   color: rgb(0,89,147) !important;
// }

.personCardIn {
  text-align: center;
  align-items: center !important;
}

.languages {
}

.hobbyIn {
  display: flex;
  flex-direction: row;
}

.d-none {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .personCardMobile {
    .ant-card {
      border-radius: $border-radius-sm;
      border: 1px solid $card-border;
      width: 100%;
      .ant-card-body {
        padding: 10px 0 22px 0;
        .title {
          display: flex;
          font-size: 21px;
          line-height: 27px;
          font-weight: 800;
          color: $black;

          .titleCountryFlag {
            span {
              width: 24px;
              margin-left: 3px;
              height: 16px;
            }
          }
        }
        .studiesTitle {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          margin: 0 1px;
          color: rgb(74, 71, 71);
          .courseLevelTitle {
            color: #00adb5;
            font-size: 13px;
            line-height: 15px;
          }
        }
        .roleTitle {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: rgb(74, 71, 71);
          .workTitle {
            color: #00adb5;
            font-size: 13px;
            line-height: 15px;
          }
        }
        .languages {
          display: none;
          .languagesIn {
            margin: 0 1px;
            flex-wrap: wrap;
          }
        }
        .hobbyMobile {
          display: none;
        }
        .countryContainer {
          margin: 0 1px;
          .countryName {
            max-width: 120px;
          }
        }

        .buddyAvgResponseTimeMobile {
          font-size: 11px;
          padding-top: 5px;
          padding-bottom: 5px;
          color: #5d5d5d;
        }
        .userChatButton {
          display: flex;
          font-size: 10px;
          width: 100%;
          flex-direction: column;
          align-items: center;
          .inUserChatButton {
            width: 100%;
            height: auto;
            padding: 7px 15px;
            svg {
              margin: 0 8px 0 0;
              font-size: $font-sm;
            }
            span {
              font-size: $font-sm;
            }
          }
          .viewProfileButton {
            font-size: $font-sm;
            color: $primary;
            font-weight: 400;
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }

  .coverImageBackroundMobile {
    position: absolute;
    width: 100%;
    padding: 0 1px;
    height: 80px;
    object-fit: cover;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  // .inPersoncardMobile {
  //   width: 100% !important;
  //   height: 350px;
  //   border-top-left-radius: 10px !important;
  //   border-top-right-radius: 10px !important;
  //   border-bottom-left-radius: 10px !important;
  //   border-bottom-right-radius: 10px !important;
  //   border: solid rgb(228, 224, 224) 2px !important;
  // }

  .imgMobile {
    border-radius: 60% !important;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: solid 3px white;
  }

  // .hobbyMobile {
  //   display: none;
  // }

  .userChatButtonMobile {
    display: flex;
    font-size: 5px !important;
    height: 100%;
    padding: 10px 20px 20px 20px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .inUserChatButtonMobile {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    //background-color: rgb(0,89,147) !important;
  }

  .inUserChatTextMobilePrsnCrd {
    padding-left: 10px !important;
    font-size: 12px !important;
    display: flex !important;
    align-items: center;
  }

  .coverImageForeground {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;
    width: 30%;
    position: relative;
  }

  .userRoleBadgeMobile {
    bottom: 20px !important;
  }

  .userCountryMobile {
    // display: none;
  }

  .ant-card-body {
    //     padding: 5px !important;
  }

  .copyLinkButtonMobile {
    padding-top: 0% !important;
    margin-top: 10px;
    padding-right: 2px;
    // background-color: #0000009c !important;
  }

  .userRoleBadge {
    padding-left: 60px;
    padding-top: 5px;
  }

  .userBadge {
    top: 35px;
    left: 37px;
  }
}
@media only screen and (max-width: 420px) {
  /* For mobile phones: */

  .personCardMobile {
    .ant-card {
      .ant-card-body {
        .userChatButton {
          .inUserChatButton {
            width: 100%;
          }
        }
      }
    }
  }

  .userBadge {
    top: 38px;
    width: 36px;
    height: 36px;
  }
}
