.tagType{
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
    top: 12px !important;
    left: 15px !important;
    position: absolute !important;
    // background-color: rgb(13,160,96) !important;
    font-size: 13px !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .tagTypeMobile{
        display: flex !important;
        align-items: center !important;
        border-radius: 4px !important;
        top: 12px !important;
        left: 15px !important;
        position: absolute !important;
        // background-color: rgb(13,160,96) !important;
        font-size: 10px !important;
    }

}

