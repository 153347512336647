/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

html {
  height: auto !important;
  overflow: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Poppins', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto !important;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-steps .ant-steps-item-tail {
  display: none !important;
}

.custom-steps .ant-steps-item .ant-steps-item-tail {
  display: none !important;
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: transparent !important;
}

.ant-steps-item-custom .ant-steps-item-container {
  border-bottom: none;
}

.ant-steps > .ant-steps-navigation > .ant-steps-item::before {
  background-color: transparent !important;
}

.ant-steps-item::before {
  border-bottom-color: transparent !important;
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: transparent !important;
}

.index-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
