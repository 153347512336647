@import '/src/Variable.scss';

.headerTopLeftPanel {
  box-shadow: 0px 2.55px 2.55px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 9;
  .header {
    display: flex;
    align-items: center;
    height: 6.48vh;
    background-color: $white;
    padding: 0 120px;
    .Logo {
      .imgLogo {
        height: 50px;
      }
    }
    .headerTopRightPanel {
      .homeNavBar {
        a {
          .homeNavBarButton {
            font-size: $font-md;
            color: $black;
            &:hover {
              color: var(--theme-color, $primary);
            }
            &.selectedTabButton {
              color: var(--theme-color, $primary);
            }
            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .shareNavBar {
        .ant-dropdown-link {
          font-size: $font-md;
          color: $black;
          display: flex;
          align-items: center;
          &:hover {
            color: var(--theme-color, $primary);
          }
          &:focus {
            color: var(--theme-color, $primary);
          }
          svg {
            margin-right: 5px;
          }
          .anticon-down {
            margin-left: 5px;
          }
        }
      }
      .myChats {
        margin-right: 14px;
        a {
          .myChatsButton {
            font-size: $font-md;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-sm;
            background-color: $primary;
            border: unset;
            box-shadow: unset;
            svg {
              font-size: 16px;
              margin: 0 7px 0 0;
            }
          }
        }
      }
      .allBuddies {
        a {
          .allBuddiesButton {
            font-size: $font-md;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-sm;
            background-color: $primary;
            border: unset;
            box-shadow: unset;
            svg {
              font-size: 18px;
              margin: -2px 6px 0 0;
            }
          }
        }
      }
      .logoutNavBar {
        .logoutNavBarButton {
          font-size: $font-md;
          font-weight: 400;
          color: $black;
          &:hover {
            color: $primary;
          }
          svg {
            margin-right: 5px;
          }
        }
      }
      .languge {
        .userLangugeImg {
          .inUserLangugeImg {
            width: auto;
            height: 13px;
          }
          .languageSelect {
            color: $black;
            &:focus {
              border: unset;
              box-shadow: unset;
            }
            .ant-select-selector {
              border: unset;
            }
            .ant-select-arrow {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.ant-layout-header {
  background: #ffffff;
}

.homeNavBar {
  padding-right: 10px;
}

.logoutNavBar {
  padding-right: 10px;
}
.allBuddies {
  padding-right: 10px;
}

.homeNavBarButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: #ffffff !important;
  // border: none !important;
  width: 100%;
  box-shadow: none !important;
}

.logoutNavBarButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: #ffffff !important;
  // border: none !important;
  width: 100%;
  box-shadow: none !important;
}

.imgLanguage {
  height: 20px;
}

.homeIcon {
  padding-bottom: 2px;
}

.logoutNavBarText {
  padding-left: 5px;
}

.myChatText {
  padding-left: 5px;
  color: rgb(255, 255, 255);
  font-size: 13px;
}

.shareNavBar {
  margin-right: 10px;
}

.shareNavBarText {
  padding-right: 5px;
}

.dropDownShareButton {
  display: flex !important;
  align-items: center !important;
  border: none;
  width: 100%;
  .emailIcon {
    //width: 25px;
  }
}

.dropDownShareButtonFb {
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  border: none;
  width: 100%;
}

.dropDownShareButtonText {
  padding-left: 5px;
  color: $black;
}

.dropDownIcon {
  font-weight: bold;
  padding-right: 2px !important;
  // padding-top: 5px !important;
}

.languge {
  padding-left: 10px;
}

.headerPanel {
  position: relative;
}

.hideMenuIcon {
  display: none;
}

.showHeaderTopRightPanel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menuButton {
  display: flex;
}

.menuButtonIn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none;
  border-color: #ffffff !important;
  box-shadow: none;
  width: 24px !important;
  svg {
    color: $gray-text !important;
  }
}

.dropDownMenuMobile {
  display: flex;
  top: 5px;
  width: 100vw;
}

.dropDownMenuMobileIn {
  width: 100vw !important;
}

.ant-dropdown-menu {
  padding: 10px 8px !important;
  margin-top: -1px !important;
  overflow-x: hidden;
  li {
    background-color: unset !important;
    .ant-dropdown-menu-title-content {
      button {
        img {
          height: 17px;
          width: 25px;
        }
      }
    }
  }
}
.ant-dropdown-menu-submenu-title {
  width: 100vw;
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item-icon {
  margin-right: 5px !important;
}

.inUserLangugeImgMobile {
}

.inUserLangugeImgMobile {
  width: 18px;
  height: 14px;
  object-fit: cover;
}

.anticon svg {
  display: block !important;
}

.ant-select-selection-item {
  font-weight: bold !important;
}

.allBuddiesMobile {
  display: none;
}

.facebookIcon {
  color: rgb(59, 89, 152);
}
.instagramIcon {
  color: white;
  border-radius: $border-radius-sm;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.twitterIcon {
  color: rgb(35, 181, 230);
}
.emailIcon {
  color: rgb(130, 130, 130);
}

.copyUrlIcon {
  color: rgb(130, 130, 130);
}

.dropDownShareButtonCopyUrl {
  background-color: unset;
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  border: none;
  width: 100%;
  padding: 0%;
}

.subDropDownMenuMobile {
  width: 100vw;
}

.allBuddies {
  display: block;
}

.allBuddiesButtonMobile {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: $border-radius-sm !important;
  border: none;
  width: 100%;
  box-shadow: none;
  color: $white !important;
}

@media only screen and (max-width: 768px) {
  .headerTopLeftPanel {
    overflow-x: hidden;

    .headerMobile {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  /* For mobile phones: */
  .showMenuIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 65px;
  }

  .myChats {
    a {
      .myChatsButton {
        font-size: 14px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-sm;
        background-color: $primary;
        border: unset;
        box-shadow: unset;
        svg {
          font-size: 16px;
          margin: 0 7px 0 0;
        }
      }
    }
  }

  .allBuddiesMobile {
    display: block;
  }

  .anticon-down {
    display: none !important;
  }

  .headerTopRightPanel {
    display: none;
    width: 120px;
  }

  .dropDownShareButton {
    display: flex !important;
    align-items: center !important;
    border: none;
    // width: 100vw;
  }

  .ant-dropdown-menu-submenu-popup ul {
    // width: 380px;
    .dropDownShareButton {
      //margin-left: -4px;
      .dropDownShareButtonText {
        color: black;
        padding-right: 7px;
      }
      .emailIcon {
        color: black;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .headerTopLeftPanel {
    .header {
      padding: 0 60px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .ant-dropdown-menu-submenu-popup ul {
    width: 340px;
  }
}
