.messageSuccessPopupTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.titleHeader {
  font-size: large;
}
.secondTitleText {
  color: rgb(135, 138, 138);
}

.PopupTitleIcon {
  display: block;
  padding-right: 10px;
  color: #019c58;
}

.titleHeaderSub {
  font-size: 12px;
  padding-top: 10px;
  font-weight: bold;
}

.PopupTitleIconHeaderLayout {
  display: flex;
  width: 100%;
  justify-content: center;
}

.referingToFriendText {
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: rgb(135, 138, 138);
}
.inReferingToFriendButton {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  border-radius: 8px !important;
  border: solid 2px rgb(198, 198, 198) !important;
  width: max-content;
  margin-right: 5px;
  min-width: 50px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: rgb(0, 0, 0) !important;
}

.inReferingToFriendButtonText {
  font-size: smaller;
  padding-left: 5px;
}

.referingToFriendButtonLayout {
  display: flex;
  justify-content: center;
}

.titleHeaderLayout {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.successMessageModalLayout {
  padding: 50px !important;
}
