$primary-color: #005993;
$border-color: #e7e7e7;
$gray-text: #777777;

@import '/src/Variable.scss';

body {
  font-family: 'Roboto', sans-serif;
}

a {
  color: $primary-color;
}

.page-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: auto;
  gap: 10px;
  height: 100vh;
}

.left-panel {
  width: 100%;
  max-width: 800px;
  padding: 20px 20px 0px 20px;
}

.right-panel {
  width: 350px;
  padding: 20px 20px 0px 20px;
  background-color: rgb(243, 243, 243);
}

.scrollable {
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 0px 0px 0px 0px;
}

.feed-card-big {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-top: 20px;
  border-radius: $border-radius-md;
  border: solid 1px $border-color;

  .top {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 15px;

    .user_image {
      border-radius: 100%;
    }

    .name-section {
      .buddy-name {
        font-size: $font-md;
        margin-bottom: 0.1em;
        font-weight: bold;
      }

      .time {
        font-size: $font-sm;
        margin-bottom: 0px;
        color: $primary-color;
      }
    }
  }

  .middle {
    padding-bottom: 20px;

    .image-grid-5 {
      $gap: 0;
      $num-cols: 4;
      $row-height: 300px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      grid-gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-grid-5-col-2 {
        grid-column: span 2;
      }

      .image-grid-5-row-2 {
        grid-row: span 2;
      }
    }

    .image-grid-4 {
      $gap: 0px;
      $num-cols: 4;
      $row-height: 300px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-grid-4-col-2 {
        grid-column: span 2;
      }

      .image-grid-4-row-1 {
        grid-row: span 1;
      }
    }

    .image-grid-3 {
      $gap: 0px;
      $num-cols: 4;
      $row-height: 300px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-grid-3-col-4 {
        grid-column: span 4;
      }

      .image-grid-3-row-2 {
        grid-row: span 1;
      }

      .image-grid-3-col-2 {
        grid-column: span 2;
      }
    }

    .image-grid-2 {
      $gap: 0px;
      $num-cols: 4;
      $row-height: 300px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-grid-4-col-2 {
        grid-column: span 2;
      }

      .image-grid-4-row-1 {
        grid-row: span 1;
      }
    }

    .image-grid-1 {
      $gap: 0px;
      $num-cols: 1;
      $row-height: 300px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-grid-4-col-1 {
        grid-column: span 1;
      }

      .image-grid-4-row-1 {
        grid-row: span 1;
      }
    }

    @media screen and (max-width: 1024px) {
      $num-cols: 2;
      $row-height: 200px;

      .image-grid-5 {
        grid-template-columns: repeat($num-cols, 1fr);
        grid-auto-rows: $row-height;
      }

      .image-grid-4,
      .image-grid-3,
      .image-grid-2,
      .image-grid-1 {
        grid-auto-rows: $row-height;
      }
    }
  }

  .bottom {
    padding: 0px 20px 20px 20px;

    .bottom-divider {
      margin-bottom: 15px;
    }

    p {
      font-size: $font-md;
    }

    .button-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-section-button {
        font-size: $font-md;
        color: $gray-text;
      }

      .likes-count {
        font-size: $font-md;
        color: $gray-text;
        margin-right: 10px;
      }
    }

    .button-icon {
      margin-right: 5px;
      font-size: $font-md;
    }
  }
}

.popular-feed-card-big {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-top: 20px;
  border-radius: $border-radius-md;

  .middle {
    .image-grid-1 {
      $gap: 0px;
      $num-cols: 1;
      $row-height: 160px;

      box-sizing: border-box;
      padding: $gap;

      display: grid;
      grid-template-columns: repeat($num-cols, 1fr);
      grid-auto-rows: $row-height;
      gap: $gap;

      .image-feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius-sm;
      }

      .image-grid-4-col-1 {
        grid-column: span 1;
      }

      .image-grid-4-row-1 {
        grid-row: span 1;
      }
    }
  }

  .bottom {
    padding: 10px 0px 10px 0px;

    p {
      font-size: $font-sm;
      margin-bottom: 0.5em;
    }

    .button-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-section-button {
        font-size: $font-sm;
        color: $primary-color;
        padding-left: 0px;
      }

      .likes-count {
        font-size: $font-sm;
        color: $gray-text;
      }
    }
  }
}

.search-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: unset;
    border: unset;
  }

  .recent-drop {
    display: flex;
    margin-right: 0px;
  }

  .recent-drop-down-text,
  .recent-drop-icon {
    color: $gray-text;
    font-weight: normal;
  }
}

.search-input {
  width: 300px;
  border-radius: 6px;
}

.video-feed {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0.6;
  font-size: 50px;
}

/* Scrollbar style START */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 20px;
  border: 3px solid #eeeeee;
}

/* Scrollbar style END */

/* Hide sidebar on mobile */
@media only screen and (max-width: 600px) {
  .right-panel {
    display: none;
  }

  .search-input {
    width: 60%;
  }
}
