@import '/src/Variable.scss';

.buddieListAreaLayout {
  display: flex;
  flex-direction: row;
  // height: calc(100vh - 132px);
  height: calc(100vh - 70px);
  background-color: $white;
}
.buddieChatAreaLayout {
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .buddieListAreaLayout {
    flex-direction: column;
    width: 100%;
  }
}

// .buddieChatAreaLayoutMobile{
//     display: none;
// }

//   @media only screen and (max-width: 768px) {
//     .buddieChatAreaLayout{
//       display: none;
//       }

//     .buddieChatAreaLayoutMobile{
//       display: flex;
//       width: 100vw !important;
//     }

//       .buddieListAreaLayout{
//         display: flex;
//         flex-direction: row;
//         background-color: rgb(255, 255, 255);

//         width: 100%;
//     }

//     .ant-empty{
//         display: none !important;
//     }

// }
